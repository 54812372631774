var _a;
import { StoresWidgetID } from '@wix/wixstores-client-core/dist/es/src/constants';
import { createViewerScript } from '@wix/wixstores-client-core/dist/es/src/viewer-script/createViewerScript';
import { cartWidgetController } from '@wix/wixstores-client-cart-icon/dist/es/src/viewerScript/cartIconController';
import { productPageController } from '@wix/wixstores-client-product-page/dist/es/src/viewerScript/productPageController';
import { galleryController } from '@wix/wixstores-client-gallery/dist/es/src/viewerScript/galleryController';
import { sliderGalleryController } from '@wix/wixstores-client-gallery/dist/es/src/viewerScript/sliderGalleryController';
import { productWidgetController } from '@wix/wixstores-client-product-widget/dist/es/src/viewerScript/productWidgetController';
import { CART_ICON_DSN, PRODUCT_PAGE_DSN, GALLERY_PAGE_DSN, SLIDER_GALLERY_DSN, PRODUCT_WIDGET_DSN, } from '@wix/wixstores-client-core/dist/es/src/viewer-script/sentryConf';
export var controllersConfig = createViewerScript((_a = {},
    _a[StoresWidgetID.CART_ICON] = {
        factory: cartWidgetController,
        sentryDSN: CART_ICON_DSN,
    },
    _a[StoresWidgetID.PRODUCT_PAGE] = {
        factory: productPageController,
        sentryDSN: PRODUCT_PAGE_DSN,
    },
    _a[StoresWidgetID.GALLERY_PAGE] = {
        factory: galleryController,
        sentryDSN: GALLERY_PAGE_DSN,
    },
    _a[StoresWidgetID.GRID_GALLERY] = {
        factory: galleryController,
        sentryDSN: GALLERY_PAGE_DSN,
    },
    _a[StoresWidgetID.SLIDER_GALLERY] = {
        factory: sliderGalleryController,
        sentryDSN: SLIDER_GALLERY_DSN,
    },
    _a[StoresWidgetID.PRODUCT_WIDGET] = {
        factory: productWidgetController,
        sentryDSN: PRODUCT_WIDGET_DSN,
    },
    _a), { useWorkerRaven: true });
